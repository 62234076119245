<template>
  <div class="video-list">
    <div class="video-left">
      <div class="video-list-content" v-loading="loading">
        <div class="video-pagination">共检索到数据{{ total }}条</div>
        <div
          class="video-box"
          v-infinite-scroll="getList"
          :infinite-scroll-disabled="scrollDisabled"
          :infinite-scroll-distance="10"
        >
          <div class="video-list-content-child">
            <div
              class="content-item"
              @click="viewDetails(item)"
              v-for="(item, i) in listData"
              :key="i"
            >
              <div class="item-img">
                <img
                  :src="
                    item.videoThumbnail
                      ? item.videoThumbnail
                      : require('@/assets/imgs/i12.png')
                  "
                  alt=""
                  :onerror="noPic"
                />
              </div>
              <div class="item-title" v-html="item.title"></div>
              <div class="item-name" v-html="item.author"></div>
              <div class="item-info">
                <span>{{ item.viewCount }}次观看</span>
                <span class="modification"></span>
                <span>
                  {{ item.publishDate }}
                </span>
              </div>
            </div>
          </div>
          <li v-if="listData.length > 0">{{ nomoreText }}</li>
        </div>
      </div>

      <noData v-if="listData.length === 0" />
    </div>
    <!-- 右侧 -->
    <div class="video-right">
      <div>
        <div class="video-right-title">关键词方案</div>
        <div class="video-right-title-border"></div>
        <div class="video-right-record">
          <ul>
            <li v-for="(item, i) in planList" :key="i">
              <span
                @click.prevent="selectKey(i, item)"
                class="history-words"
                :class="selectKeyIndex == i ? 'select-obj' : ''"
              >
                <!--  -->
                {{ item.groupName }}
              </span>
            </li>
          </ul>
          <no-data
            v-if="planList.length == 0"
            fontSize="14px"
            text="暂无关键词方案"
          />
        </div>
      </div>
      <div>
        <div class="video-right-title">近8条检索记录</div>
        <div class="video-right-title-border"></div>
        <div class="video-right-record">
          <ul>
            <li v-for="(item, i) in listRetrieval" :key="i">
              <span
                @click.prevent="selectRecord(i, item)"
                class="history-words"
                :class="selectRecordIndex == i ? 'select-obj' : ''"
              >
                <!--  -->
                {{ item.searchKey }}
                <img
                  class="close-log"
                  :src="require('@/assets/imgs/i11.png')"
                  alt=""
                  @click.stop="closeLog(item)"
                />
              </span>
            </li>
          </ul>
          <no-data
            v-if="listRetrieval.length == 0"
            fontSize="14px"
            text="暂无检索记录"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoList, logRead, dellogRead } from '@/api/channel.js'
import { keyWordsPage } from '@/api/keywords.js'
export default {
  data() {
    return {
      noPic: 'this.src="' + require('@/assets/imgs/i12.png') + '"',
      loading: false,
      searchKey: null,
      listData: [],
      // 当前页
      current: 1,
      // 每页记录数
      limit: 36,
      // 总记录数
      total: 0,
      channelId: null,
      // 右侧
      planList: [],
      selectKeyIndex: null,
      listRetrieval: [],
      selectRecordIndex: null,
      nomoreText: '加载中...',
      scrollDisabled: false,
      dataIndex: null
    }
  },
  created() {
    this.channelId = this.$route.params.channelId

    if (!this.channelId) {
      this.$router.go(-1)
    }
    this.getlogSearch()
    this.getPlan()
  },
  methods: {
    // 检索记录
    getlogSearch() {
      logRead().then(res => {
        this.listRetrieval = res
      })
    },
    // 关键词数据
    getPlan() {
      return new Promise((resolve, reject) => {
        keyWordsPage(1, 1000).then(data => {
          //
          this.planList = data.items
          resolve(data.items)
        })
      })
    },
    // 删除
    delData(row) {
      let that = this
      this.$confirm('确认删除, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          delChannel(row.id).then(res => {
            that.$message({
              type: 'success',
              message: '删除成功!'
            })

            that.getList()
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    currentChange(val) {
      this.current = val
      this.getList()
    },
    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getList()
    },

    getList() {
      this.loading = true
      this.scrollDisabled = true
      let obj = {
        channelId: this.channelId,
        inputKey: this.searchKey
      }

      videoList(this.current, this.limit, obj).then(data => {
        this.loading = false
        this.total = data.all
        if (data.items.length > 0) {
          this.current++
          this.scrollDisabled = false
          this.listData = this.listData.concat(data.items)
          if (data.items.length < this.limit) {
            this.nomoreText = '没有更多了...'
          }
        } else {
          this.nomoreText = '没有更多了...'
        }
      })
    },
    viewDetails(row) {
      this.$router.push({
        path: '/video/detail/' + row.videoId,
        query: {
          videoTitle: row.title ? row.title : row.videoId
        }
      })
    },
    // 右侧
    // 选择关键词
    selectKey(i, row) {
      if (this.searchKey == row.searchKey) {
        this.selectKeyIndex = null
        this.searchKey = null
      } else {
        this.selectKeyIndex = i
        this.searchKey = row.searchKey
      }

      this.selectRecordIndex = null

      this.current = 1
      this.listData = []
      this.getList()
    },

    selectRecord(i, row) {
      if (this.searchKey == row.searchKey) {
        this.selectRecordIndex = null
        this.searchKey = null
      } else {
        this.selectRecordIndex = i
        this.searchKey = row.searchKey
      }

      this.selectKeyIndex = null

      this.current = 1
      this.listData = []
      this.getList()
    },
    closeLog(row) {
      dellogRead(row.id).then(res => {
        this.$message({
          type: 'success',
          message: '已删除!'
        })
        this.selectRecordIndex = null
        this.getlogSearch()
      })
    }
  }
}
</script>

<style lang="scss">
.video-list {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  .video-right {
    width: 260px;
    height: calc(100% - 5px);
    padding: 10px 10px 5px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      border-radius: 5px;
      padding: 14px;
      width: 100%;
      height: 49.5%;
      overflow: auto;
      background: #ffffff;
      border: 1px solid #e5e5e5;
    }
    .video-right-title {
      padding-left: 10px;
      border-left: 4px solid #694638;
      font-size: 14px;
      font-weight: 300;
      color: #2a2a2a;
    }
    .video-right-title-border {
      border-bottom: 1px solid #e5e5e5ce;
      margin: 13px 0;
    }
    .video-right-record {
      height: calc(100% - 50px);
      overflow: hidden;
      overflow-y: auto;
      // background: #2a2a2a;
      padding-top: 10px;
      position: relative;
      ul {
        padding: 0;
        margin: 0;
        li {
          list-style: none;
          margin-bottom: 15px;
          .history-words {
            padding: 5px 10px;
            background: #efefef;
            border: 0px solid #000000;
            border-radius: 20px;
            white-space: nowrap;
            font-size: 12px;
            font-weight: 300;
            color: #2a2a2a;
            cursor: pointer;
            user-select: none;
            position: relative;
            .close-log {
              position: absolute;
              top: -5px;
              right: -5px;
              display: none;
            }
            &:hover {
              .close-log {
                display: block;
              }
            }
          }
          .select-obj {
            background: #292828;
            color: #ffffff;
          }
        }
      }
    }
  }
  .video-left {
    width: calc(100% - 260px);
    height: 100%;
    padding: 5px 10px;
    overflow: hidden;
  }

  .video-list-content {
    width: 100%;
    height: calc(100% - 45px);
    border-radius: 5px;
    .content-left {
      width: calc(100% - 370px);
      height: 100%;
    }
    .content-right {
      width: 360px;
      height: 100%;
    }
    .video-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      background: white;
      .video-list-content-child {
        padding: 5px;
        width: calc(100% + 16px);

        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
      }
      li {
        list-style: none;
        text-align: center;
        color: #555555;
        font-size: 12px;
        padding-bottom: 10px;
      }
    }
  }
  //
  .content-item {
    width: calc(100% / 6 - 9px);
    height: 245px;
    margin-right: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    &:hover {
      img {
        transform: scale(0.95);
        transition: all 0.5s;
      }
      .item-info,
      .item-name,
      .item-title {
        color: rgb(4, 163, 255);
        span {
          color: rgb(4, 163, 255);
        }
      }
    }
    .item-img {
      width: 100%;
      height: 138px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .item-title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-size: 16px;
      padding: 0 5px;
      font-weight: 600;
      color: #030303;
      margin: 10px 0;
    }
    .item-name {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-size: 12px;
      margin: 10px 0;
      font-weight: 300;
      color: #606060;
      padding: 0 5px;
    }
    .item-info {
      padding: 0 5px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;

      span {
        font-size: 12px;
        font-weight: 300;
        color: #606060;
      }
      .modification {
        display: inline-block;
        width: 2px;
        height: 2px;
        background: #1a1a1b;
        border-radius: 50%;
        vertical-align: middle;
        margin: 0 5px;
      }
    }
  }
  .video-pagination {
    margin: 5px 0;
    height: 30px;
    line-height: 30px;
    padding: 0 5px;
    font-size: 12px;
    color: #4b4b4b;
    background: #ffffff;
    border-radius: 5px;
  }
}
</style>
